<template>
  <div class="aboutHeader">
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md12>
        <v-container fill-height>
          <v-row align="center" justify="center">
            <v-layout wrap pt-12 justify-center>
              <v-flex xs12 md12 align="center">
                <v-img src="../../assets/aboutus.png" aspect-ratio="1" contain height="250px">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="#26af82"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-flex>
            </v-layout>
          </v-row>
        </v-container>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md12 text-center>
        <span style="font-size:3rem;font-weight:800;color:#00A972">Server Error</span>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style scoped>
.aboutHeader {
  min-height: 80vh;
}
</style>